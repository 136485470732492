enifed('ember-views/utils/lookup-component', ['exports', 'ember-babel', 'container', 'ember-environment', 'ember/features'], function (exports, _emberBabel, _container, _emberEnvironment, _features) {
  'use strict';

  exports.default = lookupComponent;

  var _templateObject = (0, _emberBabel.taggedTemplateLiteralLoose)(['component:-default'], ['component:-default']);

  function lookupModuleUnificationComponentPair(componentLookup, owner, name, options) {
    var localComponent = componentLookup.componentFor(name, owner, options);
    var localLayout = componentLookup.layoutFor(name, owner, options);

    var globalComponent = componentLookup.componentFor(name, owner);
    var globalLayout = componentLookup.layoutFor(name, owner);

    var localAndUniqueComponent = !!localComponent && (!globalComponent || localComponent.class !== globalComponent.class);
    var localAndUniqueLayout = !!localLayout && (!globalLayout || localLayout.referrer.moduleName !== globalLayout.referrer.moduleName);

    if (localAndUniqueComponent && localAndUniqueLayout) {
      return { layout: localLayout, component: localComponent };
    }

    if (localAndUniqueComponent && !localAndUniqueLayout) {
      return { layout: null, component: localComponent };
    }

    var defaultComponentFactory = null;

    if (!_emberEnvironment.ENV._TEMPLATE_ONLY_GLIMMER_COMPONENTS) {
      defaultComponentFactory = owner.factoryFor((0, _container.privatize)(_templateObject));
    }

    if (!localAndUniqueComponent && localAndUniqueLayout) {
      return { layout: localLayout, component: defaultComponentFactory };
    }

    var component = globalComponent || globalLayout && defaultComponentFactory;
    return { layout: globalLayout, component: component };
  }

  function lookupComponentPair(componentLookup, owner, name, options) {
    if (_features.EMBER_MODULE_UNIFICATION) {
      return lookupModuleUnificationComponentPair(componentLookup, owner, name, options);
    }

    var component = componentLookup.componentFor(name, owner, options);
    var layout = componentLookup.layoutFor(name, owner, options);

    var result = { layout: layout, component: component };

    if (!_emberEnvironment.ENV._TEMPLATE_ONLY_GLIMMER_COMPONENTS && layout && !component) {
      result.component = owner.factoryFor((0, _container.privatize)(_templateObject));
    }

    return result;
  }

  function lookupComponent(owner, name, options) {
    var componentLookup = owner.lookup('component-lookup:main');

    var source = options && options.source;

    if (source) {
      var localResult = lookupComponentPair(componentLookup, owner, name, options);

      if (localResult.component || localResult.layout) {
        return localResult;
      }
    }

    return lookupComponentPair(componentLookup, owner, name);
  }
});